<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section open section-pb48">
          <h1>Исторический очерк</h1>
          <EditorJSComponent
            :text="JSON.parse(mycityHistory.description)"
            v-if="mycityHistory && mycityHistory.description"
          />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "MyCityHistory",
  components: {
    EditorJSComponent,
  },
  async asyncData({ store }) {
    await store.dispatch("GET_MYCITY_HISTORY");
  },
  computed: {
    mycityHistory() {
      return this.$store.state?.mycity_history;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>
